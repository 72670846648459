import React from "react"
import { Link } from "gatsby"
import {Container, Row, Col, Image} from 'react-bootstrap'
import successStyles from "./Success.module.scss"
import { AiFillCheckCircle } from 'react-icons/ai';
import { IoIosMail } from 'react-icons/io';
import { IoLogoWhatsapp } from 'react-icons/io';
import { FaMapMarkerAlt } from 'react-icons/fa';
import logo from '../../../static/logo.png'
import logoFooter from '../../../static/logo-black.png'

const Success = (props) => {
    return (
        <>
            <Container fluid className={successStyles.main}>
                <Row>
                    <Col md className="text-center p-5 mb-3">
                    <Link to="/" target='_self'rel="noopener noreferrer">
                        <Image src={logo} className={successStyles.image} fluid   alt="DANA Logo"/>
                    </Link>                    
                    </Col>
                </Row>
                <Row>
                    <Col md className="text-center pt-5">
                        <AiFillCheckCircle color="#20EDD0" size="5rem"/>
                    </Col>
                </Row>
                <Row>
                    <Col md className="text-center py-3 mb-5">
                        <h2 className={successStyles.title}>MUCHAS GRACIAS POR TU COMPRA</h2>
                        <p className={successStyles.text + " my-2"} >En breve recibirás un email con la información necesaria para empezar a darle forma a tu sitio</p>  
                    </Col>
                </Row>
                <Row>
                    <Col md className={successStyles.contact + "  py-1 mb-5"}>
                        <span className="mr-3">
                            <IoIosMail color="#7600FF" size="2rem"/>
                            <a href="mailto:info@iurco.com" target='_blank'rel="noopener noreferrer">
                                info@iurco.com
                            </a>
                        </span>
                        <span className="mr-3">
                            <IoLogoWhatsapp color="#7600FF" size="2rem"/>
                            <a href="https://wa.me/541167888506" target='_blank'rel="noopener noreferrer">
                                Mandanos un whatsapp
                            </a>
                        </span>
                        <p className="mt-2">
                            <FaMapMarkerAlt color="#7600FF" size="2rem"/>
                            Cátulo Castillo 2580, Ciudad de Buenos Aires, Bs As - Argentina
                        </p>
                    </Col>
                </Row>
                <Row>
                    <footer className={successStyles.footer}>
                        <div className="text-center">
                            <Image  className={successStyles.logoFooter} src={logoFooter} alt="Dana Logo" fluid/>
                        </div>
                    </footer>  
                </Row>
            </Container>
        </>
    )
}

export default Success

