import React from "react"
import Seo from "../components/seo/Seo"


const SuccesLayout = (props) => (
    <>
        <Seo></Seo>
        {props.children}
    </>
)

export default SuccesLayout