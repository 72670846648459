import React from "react"
import SuccesLayout from '../layouts/successLayout'
import Success from "../components/success/success"




export default () => {
    return (
        <SuccesLayout>
            <Success/>
        </SuccesLayout>
    )
}

